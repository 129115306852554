import React from "react";
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import img12 from "../../images/12.png";
import shapeservice from "../../images/shapes/shape-service.webp";
import img171 from "../../images/171.jpg";
import img18 from "../../images/18.jpeg"
import serviceShape1 from "../../images/shapes/service-shape-1-1.webp"
import { GiDna2 } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

function OurServices(){
  const navigate=useNavigate();
    return (
        <section className="service-page service-page--one">
    <div
      className="service-page__bg"
      style={{
        backgroundImage:
        serviceShape1
      }}
    ></div>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div
            className="sec-title text-center wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <h6 className="sec-title__tagline">
              <img
                src={secTitles1 }
                alt=""
                className="sec-title__img"
              />
              our Service
            </h6>
            {/* /.sec-title__tagline */}
            <h3 className="sec-title__title">
              We’re offering the best <br /> services to you
            </h3>
            {/* /.sec-title__title */}
          </div>
          {/* /.sec-title */}
        </div>
      </div>
      <div className="row gutter-y-30">
        <div className="col-md-6 col-lg-4">
          <div
            className="service-card wow fadeInUp"
            data-wow-duration="1500ms"
            data-wow-delay="400ms"
          >
            <div className="service-card__inner">
              <div className="service-card__top">
                <h3 className="service-card__title">
                  <a href="" on onClick={()=>{navigate("/imageProcessing")}}>
                    AI Based Image <br /> Processing
                  </a>
                </h3>
                <div className="service-card__icon">
                <GiDna2 />
                </div>
              </div>
              <div className="service-card__thumb">
                <div className="service-card__thumb__item">
                  <img
                    src={img18}
                    alt=""
                  />
                </div>
                <div className="service-card__thumb__shape">
                  <img
                    src={shapeservice}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.col-md-6 col-lg-4 */}
        <div className="col-md-6 col-lg-4">
          <div
            className="service-card wow fadeInUp"
            data-wow-duration="1500ms"
            data-wow-delay="600ms"
          >
            <div className="service-card__inner">
              <div className="service-card__top">
                <h3 className="service-card__title">
                  <a href="" onClick={()=>{navigate("/interactivePatientHistory")}}>
                    AI Based Interactive <br /> Patient History
                  </a>
                </h3>
                <div className="service-card__icon">
                  <GiDna2 />

                </div>
              </div>
              <div className="service-card__thumb">
                <div className="service-card__thumb__item">
                  <img
                    src={img171}
                    alt=""
                  />
                </div>
                <div className="service-card__thumb__shape">
                  <img
                    src={shapeservice}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.col-md-6 col-lg-4 */}
        <div className="col-md-6 col-lg-4">
          <div
            className="service-card wow fadeInUp"
            data-wow-duration="1500ms"
            data-wow-delay="800ms"
          >
            <div className="service-card__inner">
              <div className="service-card__top">
                <h3 className="service-card__title">
                  <a href="" onClick={()=>{navigate("/dataAnalytics")}}>
                    AI Based Data <br /> Analytics
                  </a>
                </h3>
                <div className="service-card__icon">
                <GiDna2 />
                </div>
              </div>
              <div className="service-card__thumb">
                <div className="service-card__thumb__item">
                  <img src={img12} />
                </div>
                <div className="service-card__thumb__shape">
                  <img
                    src={shapeservice}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.col-md-6 col-lg-4 */}
      </div>
      {/* /.row */}
    </div>
  </section>
  
    )
}

export default OurServices;