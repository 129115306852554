import React from "react";
import logo1 from "../../images/logo-1.png"
import footerShape11 from "../../images/shapes/footer-shape-1-1.webp"
import footerShape12 from "../../images/shapes/footer-shape-1-2.webp"
function Footer(){
    return (
        <footer className="main-footer main-footer--one">
    <div className="main-footer__inner">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-xl-4">
            <div className="footer-widget footer-widget--info">
              <a href="inedx.html" className="footer-widget__logo">
                <img
                  src={logo1}
                  width={130}
                  alt="footer logo"
                />
              </a>
              {/* /.footer-widget__logo */}
              <p className="footer-widget__text">
                neuronai is a community of AI, Edge, Digital and cross industry
                experts, who help Re-imagine your business to Digital and AI
                transformation.{" "}
              </p>
              {/* /.footer-widget__text */}
            </div>
            {/* /.footer-widget */}
          </div>
          {/* /.col-md-6 */}
          <div className="col-md-6 col-xl-2">
            <div className="footer-widget footer-widget--link">
              <h4 className="footer-widget__title">Links</h4>
              {/* <a href="https://neuronai.in/index.php/about-neuronai/">About Us</a> */}
              {/* <a href="https://neuronai.in/index.php/reach-us/">Reach Us</a> */}
              <ul className="list-unstyled footer-widget__links">
                <li className="footer-widget__links__item">
                  <a href="https://neuronai.in/index.php/about-neuronai/">
                    About Us
                  </a>
                </li>
                <li className="footer-widget__links__item">
                  <a href="https://neuronai.in/index.php/reach-us/">Reach Us</a>
                </li>
                <li className="footer-widget__links__item">
                  <a href="https://neuronai.in/index.php/services/">
                    Our Services
                  </a>
                </li>
                <li className="footer-widget__links__item">
                  <a href="https://neuronai.in/index.php/product/">
                    Products &amp; Solution
                  </a>
                </li>
              </ul>
              {/* /.list-unstyled footer-widget__links */}
            </div>
            {/* /.footer-widget */}
          </div>
          {/* /.col-md-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </div>
    {/* /.main-footer__middle */}
    <div className="main-footer__bottom">
      <div className="container">
        <div className="main-footer__bottom__inner">
          <p className="main-footer__copyright">
            {" "}
            © Copyright <span className="dynamic-year" /> by neuronai.in
          </p>
        </div>
        {/* /.main-footer__inner */}
      </div>
      {/* /.container */}
    </div>
    {/* /.main-footer__bottom */}
    <div className="main-footer__shape">
      <img
        src={footerShape11}
        alt=""
      />
    </div>
    <div className="main-footer__shape main-footer__shape--two">
      <img
        src={footerShape12}
        alt=""
      />
    </div>
  </footer>
    )
}
export default Footer;