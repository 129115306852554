
import React from 'react';
import './MatrixVisualization.css';
import HeatMap from "react-heatmap-grid";

const MatrixVisualization = ({ data=[] }) => {
//   return (
//     <div className="matrix-container">
//       {data.map((row, rowIndex) => (
//         <div key={rowIndex} className="matrix-row">
          
//             <div key={1*rowIndexs} className="matrix-cell1">
//               {row[0]}
//             </div>
//             <div key={2*rowIndex} className="matrix-cell2">
//             {row[1]}
//           </div>
         
//         </div>
//       ))}
//     </div>
//   );
const xLabels=["0","1"]
const yLabels=["0","1"]
console.log(data);
// const data=[[10,65],[90,100]]
// const xLabelsVisibility = [true,true]
return (
    <div style={{background:"#cedede",padding:"20px"}} >
    {/* <div style={{textAlign:"center",color:"#021936",fontSize:"22px",fontWeight:"500"}}>Confusion Matrix</div> */}
    <HeatMap

        xLabels={xLabels}
        yLabels={yLabels}
        xLabelsLocation={"none"}
        
        // xLabelsVisibility={xLabelsVisibility}
        // xLabelWidth={60}
        // width={140}
        data={data}
        squares
        cellStyle={(background, value, min, max, data, x, y) => ({
          background: 1 - ((max - value) / (max - min))<0.3?"white":`rgb(7,80,124, ${1 - ((max - value) / (max - min))})`,
          color: 1 - ((max - value) / (max - min))<0.3?"021936":"white",
          fontSize: "13px",
          flex:"unset",
          // height:"fitContent",
          padding:"30px",
          alignContent:"center",
          // innerWidth:"40px",
          // width:"140px",
          width:"90px",
          height:"90px",
          innerHeight:"40px",
          maxWidth:"250px",
          // height:"70px"
        //   color: "#444"
        })}
        cellRender={value => value && <div>{value}</div>}
      /></div>
)
};

export default MatrixVisualization;