import React from "react";
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import workTeps from "../../images/shapes/work-teps.webp";
import patientDiagnosisCare from "../../images/patient_diagnosis_care.gif";

function OurWork(){
    return (<section className="our-work">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div
            className="sec-title text-center wow fadeInUp"
            data-wow-duration="700ms"
          >
            <h6 className="sec-title__tagline">
              <img
                src={secTitles1}
                alt="Our work Steps"
                className="sec-title__img"
              />
              Neuron AI’s Gen AI Model for Patient Diagnosis &amp; Care
            </h6>
            {/* /.sec-title__tagline */}
            <h3 className="sec-title__title">
              Step by step process for neuronAI <br />
              Clinical Decision Making{" "}
            </h3>
            {/* /.sec-title__title */}
          </div>
          {/* /.sec-title */}
        </div>
      </div>
      <div className="row">
        <img
          src={patientDiagnosisCare}
          alt=""
        />
      </div>
      <div className="row gutter-y-30">
        <div className="col-lg-3 col-md-6">
          <div className="our-work__item">
            <div className="our-work__item__step" />
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="#">
                  Patient walks-in with symptoms of breathing difficulty
                </a>
              </h4>{" "}
              {/*
                          <p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="our-work__item">
            <div className="our-work__item__step" />
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="">Doctor prescribes tests</a>
              </h4>
              {/*<p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="our-work__item">
            <div className="our-work__item__step" />
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="">An image of patient's lungs are taken</a>
              </h4>
              {/*<p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="">the doctor voice records the visit</a>
              </h4>
              {/*<p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="">Other medical tests are taken</a>
              </h4>
              {/*  <p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="our-work__item our-work__item--two">
            <div className="our-work__item__step" />
            <div className="our-work__item__content">
              <h4 className="our-work__item__title">
                <a href="services.html">
                  ML Model aids doctor with diagnosis for an appropriate
                  treatment plan <br />
                  delivered
                </a>
              </h4>
              {/*<p class="our-work__item__text">There are many variations of passages of Lorem Ipsum available, but the </p> */}
              <div className="our-work__item__shape">
                <img
                  src={workTeps}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>)
}

export default OurWork;