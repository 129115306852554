import React from "react";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { IoMdMail } from "react-icons/io";

function Header(){
    return (<div className="topbar-one">
    <div className="container-fluid">
      <div className="topbar-one__inner">
        <ul className="list-unstyled topbar-one__info">
          <li className="topbar-one__info__item">
          <IoMdMail color="#0EBD66"/>
            <a
              className="topbar-one__info__item__email"
              href="mailto:connect@neuronai.in"
            >
              connect@neuronai.in
            </a>
          </li>
        </ul>
        <div className="topbar-one__right">
          <div className="topbar-one__social">
            <a href="https://www.linkedin.com/groups/14363747/" target="_blank">
            <FaLinkedin size={"25px"}/>
            </a>
              </div>
              <div className="topbar-one__social">
            <a href="https://www.linkedin.com/groups/9212278/" target="_blank">
            <FaTwitter size={"25px"}/>

            </a>
              </div>
        </div>{" "}
      </div>
    </div>
  </div>)
}
export default Header;