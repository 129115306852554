import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx"
import Upload from "../../components/Upload"
import axios from "axios";
function UploadAudio({setShowModal}){
    const [file,setFile]=useState()
    const predict=()=>{
        const formData=new FormData()
      formData.append('file',file)
      const response=  axios.post(`https://health.neuronai.life/upload_file`,
        formData,
        {
          headers : {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin' :"*"
          }}
        
      )
      response.then((res)=>{
        console.log(res);
        // setMatrixData(res.data)
      })
    }
    return (<div className="modal-div">
    <div className="modal-content">
    <div className="modal-header">
       <span> Diagnose online by uploading</span> <RxCross1 size={"25PX"} onClick={()=>{console.log("hmm");;setShowModal(false)}}/>
        
    </div>
    <Upload uploadType={".mp4,audio/*"} setFile={setFile}/>
    <div onClick={predict}>Predict</div>
    </div>
</div>)
}
export default UploadAudio;