
import React, { useEffect } from "react";
import { RxCross1 } from "react-icons/rx"
import Upload from "../../components/Upload"
function UploadCSV({loading,setShowModal,csv,setCSV,onUpload}){
    useEffect(()=>{console.log(csv,"useeffect");},[csv])
    
    return (<div className="modal-div">
    <div className="modal-content">
    <div className="modal-header">
       <span> Diagnose online by uploading</span> <RxCross1 size={"25PX"} onClick={()=>{console.log("hmm");;setShowModal(false)}}/>
        
    </div>
    <marquee style={{color:"orange"}}>{loading==false?"Choose the CSV file and click on upload to fetch results":"Analysis in progress"}</marquee>
    <Upload uploadType={".csv"} setFile={setCSV} onUpload={onUpload}/>
    </div>
</div>)
}
export default UploadCSV;