
import React from "react";
import logo1 from "../../images/logo-1.png"
import { BiPhoneCall } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
    const navigate=useNavigate();
    return(<header className="main-header sticky-header sticky-header--normal">
      <div className="container-fluid">
        <div className="main-header__inner">
          <div className="main-header__logo">
            <a href="https://neuronai.in/">
              <img
                src={logo1}
                alt=""
                width={133}
              />
            </a>
          </div>
          {/* /.main-header__logo */}
          <nav className="main-header__nav main-menu">
            <ul className="main-menu__list">
              <li>
                <a href="https://neuronai.in/">Home</a>
              </li>
              <li>
                <a href="https://neuronai.in/index.php/about-neuronai/">
                  About neuronai
                </a>
              </li>
              <li className="dropdown">
                <a href="https://neuronai.in/index.php/product/">
                  Products &amp; Solutions
                </a>
                <ul className="sub-menu">
                  <li>
                    <a href="https://health.neuronai.in/">Health Neuron</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="https://neuronai.in/index.php/services/" target="_blank">
                  Our Services
                </a>
              </li>
              {/*<li>
                          <a href="https://neuronai.in/index.php/team-neuron/">Team Neuron</a>
                      </li> */}
              {/*<li>
                          <a href="https://neuronai.in/index.php/reach-us/">Reach us</a>
                      </li> */}
            </ul>
          </nav>{" "}
          {/* /.main-header__nav */}
          <div className="main-header__right">
            <div className="main-header__link">
              <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>navigate("/imageProcessing")}
                className="laboix-btn main-header__btn"
              >
                Image Processing
              </a>
            </div>
            <div className="main-header__link">
              <a
                onClick={()=>navigate("/interactivePatientHistory")}
                className="laboix-btn main-header__btn"
              >
                Interactive Patient History
              </a>
            </div>
            <div className="main-header__link">
              <a
               onClick={()=>navigate("/dataAnalytics")}
                className="laboix-btn main-header__btn"
              >
                Data Analytics
              </a>
            </div>
            <div className="mobile-nav__btn mobile-nav__toggler">
              <span />
              <span />
              <span />
            </div>
            {/* /.mobile-nav__toggler */}
            <a
              href="https://neuronai.in/index.php/reach-us/"
              className="main-header__right__call"
            >
              <div className="main-header__right__icon">
              <BiPhoneCall />
              </div>
            </a>
          </div>
          {/* /.main-header__right */}
        </div>
        {/* /.main-header__inner */}
      </div>
      {/* /.container-fluid */}
    </header>
    );
};

export default Navbar;
