import React, { useEffect, useRef, useState } from "react";
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import './index.scss'
import footerShape11 from "../../images/shapes/footer-shape-1-1.webp"
import aboutShape21 from "../../images/shapes/about-shape-2-1.webp"
import UploadCSV from "./UploadCSV";
import axios from "axios";
import ConfusionMatrixGraph from "./utils/ConfusionMatrix";
import MatrixVisualization from "./utils/ConfusionMatrix";
import ResultDisplay from "./ResultDisplay";
// import Steps from "./Steps";
function DataAnalytics(){
    const [showModal,setShowModal]=useState(false)
    const [step,setStep]=useState(1)
    const [csv,setCSV]=useState()
    const [matrixData,setMatrixData]=useState()
    const [loading,setLoading]=useState(false)
    useEffect(()=>{console.log(csv,"useeffect");},[csv])
    const Upload=(file)=>{
      
      setLoading(true);
      const formData=new FormData()
      formData.append('file',csv)
      const response=  axios.post(`https://health.neuronai.life/upload`,
        formData,
        {
          headers : {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin' :"*"
          }}
        
      )
      response.then((res)=>{
        setMatrixData(res.data)
        setStep(2);
        setLoading(false)
        // setShowModal(false)
      })
    }
    // useEffect(()=>{if(step==2){
    //   console.log(csv);
    //   const formData=new FormData()
    //   formData.append('file',csv)
    //   const response=  axios.post(`https://health.neuronai.life/upload`,
    //     formData,
    //     {
    //       headers : {
    //           'Content-Type': 'multipart/form-data',
    //           'Access-Control-Allow-Origin' :"*"
    //       }}
        
    //   )
    //   response.then((res)=>{
    //     console.log(res);
    //     setMatrixData(res.data)
    //   })
    // }},[showModal])
    return (
        <div className="DataAnalytics">
            <div className="DataAnalytics__header">
            <div className="row">
        <div className="col-12">
          <div
            className="sec-title-ip text-center wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <h6 className="sec-title-ip__tagline">
              <img
                src={secTitles1 }
                alt=""
                className="sec-title-ip__img"
              />
              Health NeuronAI
            </h6>
            {/* /.sec-title-ip__tagline */}
            <h3 className="sec-title-ip__title">
              Diagnose Cancer with AI<br /> 
            </h3>
            {/* /.sec-title-ip__title */}
          </div>
          {/* /.sec-title-ip */}
        </div>
            </div></div>
            <div className="DataAnalytics__body">
                <div className="DataAnalytics__body__banner">
                    {showModal ==true?
                        step==1?<UploadCSV loading={loading} setShowModal={setShowModal} csv={csv} setCSV={setCSV} onUpload={Upload}/>:
                        step==2?<ResultDisplay  matrixData={matrixData} setShowModal={setShowModal}/>:
                        <></>
                        :<></>
                    
                    }
                </div>
            <div className="container-custom">
        <div className="container-custom__buttons">
      <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(1);setShowModal(true)}}
                className="laboix-btn-custom"
              >
                Upload & Analyze
              </a>
              {/* <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(2);setShowModal(true)}}
                className="laboix-btn-custom"
              >
               Model Analytics
              </a> */}
              
      </div></div>
      {/* <div className="upload-predict" ref={uploadRef}>jhhhhhhhhhhhhhhhhhhhhhhhhh</div> */}
     {/* <Upload /> */}
   
     <div className="about-two__shape">
      <img
        src={aboutShape21}
        alt=""
      />
    </div>
            </div>
        </div>
    )
}

export default DataAnalytics;