import React, { useEffect, useRef, useState } from "react";
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import './index.scss'
import footerShape11 from "../../images/shapes/footer-shape-1-1.webp"
import aboutShape21 from "../../images/shapes/about-shape-2-1.webp"
import Steps from "./Steps";
function ImageProcessing(){
    const [showModal,setShowModal]=useState(false)
    const [step,setStep]=useState(1)
    useEffect(()=>{console.log("chh",showModal);},[showModal])
    return (
        <div className="imageProcessing">
            <div className="imageProcessing__header">
            <div className="row">
        <div className="col-12">
          <div
            className="sec-title-ip text-center wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <h6 className="sec-title-ip__tagline">
              <img
                src={secTitles1 }
                alt=""
                className="sec-title-ip__img"
              />
              Health NeuronAI
            </h6>
            {/* /.sec-title-ip__tagline */}
            <h3 className="sec-title-ip__title">
              Breast Cancer Detection & Diagnosis<br /> 
            </h3>
            {/* /.sec-title-ip__title */}
          </div>
          {/* /.sec-title-ip */}
        </div>
            </div></div>
            <div className="imageProcessing__body">
                <div className="imageProcessing__body__banner">
                    {showModal ==true?
                        step==1?<Steps.patientInfo setShowModal={setShowModal}/>:
                        step==2?<Steps.train setShowModal={setShowModal}/>:
                        step==3?<Steps.evaluate setShowModal={setShowModal}/>:
                        step==4?<Steps.predict setShowModal={setShowModal} />:
                        <></>
                        :<></>
                    
                    }
                </div>
            <div className="container-custom">
        <div className="container-custom__buttons">
      <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(1);setShowModal(true)}}
                className="laboix-btn-custom"
              >
                Patient Scan Info
              </a>
              <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(2);setShowModal(true)}}
                className="laboix-btn-custom"
              >
                Patient Diagnosis
              </a>
              <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(3);setShowModal(true)}}
                className="laboix-btn-custom"
              >
               Diagnosis Accuracy 
              </a>
              <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(4);setShowModal(true)}}
                className="laboix-btn-custom"
              >
                Predict Model
              </a>
      </div></div>
      {/* <div className="upload-predict" ref={uploadRef}>jhhhhhhhhhhhhhhhhhhhhhhhhh</div> */}
     {/* <Upload /> */}
    { showModal&&setStep==4&&<div>
      {/* {response} */}
     </div>}
     <div className="about-two__shape">
      <img
        src={aboutShape21}
        alt=""
      />
    </div>
            </div>
        </div>
    )
}

export default ImageProcessing;