import React, { useRef } from "react";
import ReactDOM from "react-dom/client";
import { RxCross1 } from "react-icons/rx"
import { AudioRecorder } from 'react-audio-voice-recorder';
import WebMToMP3Converter from "./utils/WebMToMP3Converter";
function RecordAudio({setShowModal}){ const audioRef = useRef(null);
    const addAudioElement = (blob) => {
      console.log(blob);
      // WebMToMP3Converter(blob);
        // const url = URL.createObjectURL(blob);
        // const audio = document.createElement("audio");
        // audio.src = url;
        // audio.controls = true;
        // document.body.appendChild(blob);
        const url = URL.createObjectURL(blob,"audio/mp4");
       

const a = document.createElement("a");
a.href = url;
a.download=url;
a.click()
      };
    return (
        <div className="modal-div">
                        <div className="modal-content-patient-info">
                        <div className="modal-header">
                           <span>Record Audio</span> <RxCross1 size={"25PX"} onClick={()=>{setShowModal(false)}}/>
                            
                        </div>
                        <div>Click on the icon to start recording</div>
                        <AudioRecorder 
      onRecordingComplete={addAudioElement}
      downloadFileExtension="mp3"
      audioTrackConstraints={{
        noiseSuppression: true,
        echoCancellation: true,
      }} 
      
      // downloadOnSavePress={true}
      
    />
                        </div>
                        </div>
    );
}
export default RecordAudio;