import { RxCross1 } from "react-icons/rx"
import MatrixVisualization from "./utils/ConfusionMatrix"
 export default function ResultDisplay({ matrixData,setShowModal}){
    return (
        <div className="modal-div">
    <div className="modal-content" style={{width:"70%",height:"70%",overflow:"auto"}}>
    <div className="modal-header">
       <span>Analysis Result</span> <RxCross1 size={"25PX"} onClick={()=>{setShowModal(false)}}/>
        
    </div>
    <div>
         {/* {loading==true && <marquee>Analysis in progress</marquee>} */}
    </div>
    <div className="models">
    {
Object.keys(matrixData).map((ey,value)=>{
    return (
      <div className="model">
        <div className="matrixlabel">{ey}</div>
        <div className="accuracyLabel">Accuracy - {matrixData[ey].accuracy}</div>
        <MatrixVisualization data={matrixData[ey].confusion_matrix} />
        </div>
    )
  })
    }
    </div></div>
</div>
    )
 }