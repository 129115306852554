 import React from "react"
import t5 from "../../images/t5.cms";
import banner1 from "../../images/banner1.webp"
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import aboutShape21 from "../../images/shapes/about-shape-2-1.webp"
import "../../static/vendors/laboix-icons/style.css"
import { GiDrippingTube } from "react-icons/gi";
import { RiFocus3Line } from "react-icons/ri";
 function About(){
    return (
        <section className="about-two">
    <div className="container">
      <div className="row" style={{ paddingBottom: "9%" }}>
        <div className="col-lg-6">
          <div
            className="about-two__left wow fadeInLeft"
            data-wow-duration="700ms"
            data-wow-delay="500ms"
          >
            <div className="about-two__thumb">
              <div className="about-two__thumb__item">
                <img
                  src={t5}
                  alt="laboix"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="about-two__thumb__item about-two__thumb__item--two">
                <img
                  src={banner1}
                  alt="laboix"
                  style={{ width: "80%" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="about-two__right">
            <div className="about-two__top">
              <div
                className="sec-title text-start wow fadeInUp"
                data-wow-duration="1500ms"
              >
                <h6 className="sec-title__tagline">
                  <img
                    src={secTitles1}
                    alt="About Us"
                    className="sec-title__img"
                  />
                  Healthcare Services
                </h6>
                {/* /.sec-title__tagline */}
                <h3 className="sec-title__title">
                  Trailblazing AI technologies with groundbreaking impact
                </h3>
                {/* /.sec-title__title */}
              </div>
              {/* /.sec-title */}
              <p className="about-two__top__text">
                Machine learning algorithms, trained on extensive datasets,
                demonstrate remarkable proficiency in analyzing diagnostic
                images, capturing voice, and providing predictive analytics to
                develop personalized treatment plans.
              </p>
            </div>
            <div
              className="about-two__feature wow fadeInUp"
              data-wow-duration="700ms"
              data-wow-delay="500ms"
            >
              <div className="about-two__feature__item">
                <div className="about-two__feature__icon">
                <RiFocus3Line />
                </div>
                <h4 className="about-two__feature__title">
                  <a href="#">Clinical Decision Making</a>
                </h4>
                <p className="about-two__feature__text">
                  Tailored treatment options Evidence based therapies
                </p>
              </div>
              <div className="about-two__feature__item">
                <div className="about-two__feature__icon">
                <GiDrippingTube />
                </div>
                <h4 className="about-two__feature__title">
                  <a href="#">Healthcare data management with AI</a>
                </h4>
                <p className="about-two__feature__text">
                  Predictive Analytics using patient data
                </p>
              </div>
            </div>
            <div
              className="about-two__feature wow fadeInUp"
              data-wow-duration="700ms"
              data-wow-delay="500ms"
            >
              <div className="about-two__feature__item">
                <div className="about-two__feature__icon">
                <RiFocus3Line />
                </div>
                <h4 className="about-two__feature__title">
                  <a href="#">Personalized Medication and Care</a>
                </h4>
                <p className="about-two__feature__text">
                  Wearable technology data predictions – includes HRV, O2
                  saturation levels etc
                </p>
              </div>
              <div className="about-two__feature__item">
                <div className="about-two__feature__icon">
                <GiDrippingTube />
                </div>
                <h4 className="about-two__feature__title">
                  <a href="#">Risk Prediction for Catastrophic Health Events</a>
                </h4>
                <p className="about-two__feature__text">
                  Predicting Pandemics <br /> Develop preventive.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="about-two__shape">
      <img
        src={aboutShape21}
        alt=""
      />
    </div>
    {/* <div class="about-two__shape--two">
          <img src="{{ url_for('static', filename='images/1t1.gif') }}" alt="" style="padding-left: 50%; width: 100%;">
	    </div> */}
  </section>
  
    )
 }
 export default About;