import serviceshape1 from "../../images/shapes/service-shape-1-1.webp"
import fav from "../../images/favicons/fav.png"
import logoLight from "../../images/logo-light.webp";
import img18 from "../../images/18.jpeg"
import Header from '../../components/header';
import Navbar from '../../components/menu';
import  { Slideshow } from '../../components/Banner';
import About from './About';
import OurServices from './OurServices';
import OurWork from './OurWork';
import Footer from '../../components/footer/Footer';
import { IoMdMail } from "react-icons/io";
import { Banner } from "../../components/Banner/banner";
function Home(){
    return (
        <>
        
  <link
    rel="shortcut icon"
    href={fav}
    type="image/x-icon"
  />
  <link
    rel="apple-touch-icon"
    sizes="180x180"
    // href={appleTouchIcon}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="32x32"
    href={fav}
  />
  <link
    rel="icon"
    type="image/png"
    sizes="16x16"
    href={fav}
  />

  <div className="page-wrapper">
  <Header/>
    <Navbar />
    <Banner/>
    {/* <Slideshow/> */}
    <About/>
    <OurServices />
  <OurWork/>
  <Footer/>
  <div className="mobile-nav__wrapper">
    <div className="mobile-nav__overlay mobile-nav__toggler" />
    {/* /.mobile-nav__overlay */}
    <div className="mobile-nav__content">
      <span className="mobile-nav__close mobile-nav__toggler">
        <i className="fa fa-times" />
      </span>
      <div className="logo-box">
        <a
          href="{{ url_for('static', filename='index.html') }}"
          aria-label="logo image"
        >
          <img
            src={logoLight}
            width={155}
            alt="labiox"
          />
        </a>
      </div>
      {/* /.logo-box */}
      <div className="mobile-nav__container" />
      {/* /.mobile-nav__container */}
      <ul className="mobile-nav__contact list-unstyled">
        <li>
        <IoMdMail color='#0EBD66'/>
          <a href="">connect@neuronai.in</a>
        </li>
      </ul>
      {/* /.mobile-nav__contact */}
      <div className="mobile-nav__social">
        <a href="https://twitter.com">
          {" "}
          <i className="fab fa-twitter" aria-hidden="true" />{" "}
          <span className="sr-only">Twitter</span>{" "}
        </a>
        <a href="https://facebook.com">
          {" "}
          <i className="fab fa-facebook-f" aria-hidden="true" />{" "}
          <span className="sr-only">Facebook</span>{" "}
        </a>
        <a href="https://pinterest.com">
          {" "}
          <i className="fab fa-pinterest-p" aria-hidden="true" />{" "}
          <span className="sr-only">Pinterest</span>
        </a>
        <a href="https://instagram.com">
          {" "}
          <i className="fab fa-instagram" aria-hidden="true" />{" "}
          <span className="sr-only">Instagram</span>
        </a>
      </div>
      {/* /.mobile-nav__social */}
    </div>
    {/* /.mobile-nav__content */}
  </div>
  {/* /.mobile-nav__wrapper */}
  <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
    <span className="scroll-to-top__text">back top</span>
    <span className="scroll-to-top__wrapper">
      <span className="scroll-to-top__inner" />
    </span>
  </a>
    </div></>
    )
}
export default Home;