
import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import { GrGallery } from "react-icons/gr";
import { IoCloudUploadSharp } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { IoImageOutline } from "react-icons/io5";
import "./index.scss"
export default function Upload({uploadType,setFile,onUpload}) {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);
    
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;
        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });
        setFile(e.files[0])
        console.log(e.files[0]);
        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 50000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex',justifyContent:"space-between", alignItems: 'center' }}>
                <div style={{display:"flex",gap:"10px"}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}</div>
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 5 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap background-color-blue">
                <div className="inline-flex align-items-center" style={{ width: '40%' }}>
                    {/* <img alt={file.name} role="presentation" src={file.objectURL} width="100" /> */}
                     {file.name}
                    
                </div>
                {/* <Tag value={props.formatSize} severity="warning" className="px-3 py-2" /> */}
                {/* <Button type="button" icon={<IoImageOutline /> */}
{/* } className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} /> */}
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)',color: 'var(--surface-d)' }}><IoImageOutline />
</i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Drag and Drop Image Here
                </span>
            </div>
        );
    };
// const onUpload=(e)=>{
//    console.log(e,"anu"); 
//    setFile(e.files)
//    console.log(e.files);
// }
    const chooseOptions = { icon: <GrGallery size={"25px"}/>
    , iconOnly: true, className: 'custom-choose-btn  p-button-outlined' };
    const uploadOptions = { icon: <IoCloudUploadSharp size={"25px"}/>
    , iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: <RxCross2 size={"25px"}/>
    , iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div style={{textAlign:"center"}}>
            <Toast ref={toast}></Toast>

            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
            {/* <FileUpload name="demo[]" mode='basic' url={'/api/upload'} multiple accept="*" onSelect={(e)=>{console.log(e);setFile(e.files[0])}} maxFileSize={1000000} onUpload={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} /> */}
            {/* <FileUpload mode="basic" name="demo[]" url="/api/upload" accept="*" onSelect={(e)=>{console.log(e);setFile(e.files[0])}} maxFileSize={1000000} onUpload={onUpload} auto chooseLabel="Browse" /> */}
            <FileUpload name="demo[]"  accept={uploadType} maxFileSize={1000000}  onSelect={(e)=>{console.log(e);setFile(e.files[0])}} chooseLabel='Choose' uploadLabel='Submit'  emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} customUpload  uploadHandler={(e)=>onUpload(e.files[0])}/>

            {/* <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload"  accept={uploadType} maxFileSize={5000000}
                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} /> */}
        </div>
    )
}
        