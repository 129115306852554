import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import banner1 from "../../images/banner.png"
// import "./_banner.scss"
import banner2 from "../../images/14.jpeg"
import banner3 from "../../images/15.jpeg"
import "./index.scss"
import aboutShape11 from "../../images/shapes/about-shape-2-1.webp"
import hero11 from "../../images/shapes/hero-1-1.webp"
import hero12 from "../../images/shapes/hero-1-2.webp"
import hero13 from "../../images/shapes/hero-1-3.webp"
import hero14 from "../../images/shapes/hero-1-4.webp"

const images = [
    {
        slideBg:banner1,
        content:"neuronAI healthcare service",
        subTitle:"The AI platform based on image processing"
    },{
        slideBg:banner2,
        content:"neuronAI healthcare service",
        subTitle:"The AI based on interactive patient history"
    },{
        slideBg:banner3,
        content:"neuronAI healthcare service",
        subTitle:"The AI platform based on data analytics"
    }
];
const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    backgroundSize: 'cover',
    height: '500px',
    // width:"100%"
  }
export const Banner = () => {
    return (
        <div className="slide-container">
          <Slide duration={2000}>
              <div class="item">
              <div class="main-slider-one1__item">
                  <div class="main-slider-one1__bg" ></div>
                  <div class="container">
                      <div class="row">
                          <div class="col-lg-7">
                              <div class="main-slider-one1__content">
                                  <h5 class="main-slider-one1__sub-title">neuronAI healthcare service</h5>
                                  <h2 class="main-slider-one1__title">The AI platform based on image processing</h2>
                                  <div class="main-slider-one1__btn">
                                      <a href="{{ url_for('image_processing') }}" class="laboix-btn">Get Started</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="main-slider-one1__shape main-slider-one1__shape--one">
                      <img src={hero11} alt/>
                  </div>
                  <div class="main-slider-one1__shape main-slider-one1__shape--two">
                      <img src={hero12} alt/>
                  </div>
                  <div class="main-slider-one1__shape main-slider-one1__shape--three">
                      <img src={hero13} alt/>
                  </div>
                  <div class="main-slider-one1__shape main-slider-one1__shape--fore">
                      <img src={hero14} alt/>
                  </div>
              </div></div>
              <div class="item">
              <div class="main-slider-one2__item">
                  <div class="main-slider-one2__bg" ></div>
                  <div class="container">
                      <div class="row">
                          <div class="col-lg-7">
                              <div class="main-slider-one2__content">
                                  <h5 class="main-slider-one2__sub-title">neuronAI healthcare service</h5>
                                  <h2 class="main-slider-one2__title">The AI based on interactive patient history</h2>
                                  <div class="main-slider-one2__btn">
                                      <a href="{{ url_for('image_processing') }}" class="laboix-btn">Get Started</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="main-slider-one2__shape main-slider-one2__shape--one">
                      <img src={hero11} alt/>
                  </div>
                  <div class="main-slider-one2__shape main-slider-one2__shape--two">
                      <img src={hero12} alt/>
                  </div>
                  <div class="main-slider-one2__shape main-slider-one2__shape--three">
                      <img src={hero13} alt/>
                  </div>
                  <div class="main-slider-one2__shape main-slider-one2__shape--fore">
                      <img src={hero14} alt/>
                  </div>
              </div></div>
            
              <div class="item">
              <div class="main-slider-one3__item">
                  <div class="main-slider-one3__bg"></div>
                  <div class="container">
                      <div class="row">
                          <div class="col-lg-7">
                              <div class="main-slider-one3__content">
                                  <h5 class="main-slider-one3__sub-title">neuronAI healthcare service</h5>
                                  <h2 class="main-slider-one3__title">The AI platform based on data analytics</h2>
                                  <div class="main-slider-one3__btn">
                                      <a href="{{ url_for('image_processing') }}" class="laboix-btn">Get Started</a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="main-slider-one3__shape main-slider-one3__shape--one">
                      <img src={hero11} alt/>
                  </div>
                  <div class="main-slider-one3__shape main-slider-one3__shape--two">
                      <img src={hero12} alt/>
                  </div>
                  <div class="main-slider-one3__shape main-slider-one3__shape--three">
                      <img src={hero13} alt/>
                  </div>
                  <div class="main-slider-one3__shape main-slider-one3__shape--fore">
                      <img src={hero14} alt/>
                  </div>
              </div></div>
            

          </Slide>
         
    </div>
    
      )
}