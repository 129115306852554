import React, { useEffect, useRef, useState } from "react";
import secTitles1 from "../../images/shapes/sec-title-s-1.webp"
import './index.scss'
import footerShape11 from "../../images/shapes/footer-shape-1-1.webp"
import aboutShape21 from "../../images/shapes/about-shape-2-1.webp"
import RecordAudio from "./RecordAudio";
import UploadAudio from "./UploadAudio";
// import Steps from "./Steps";
function InteractivePatientHistory(){
    const [showModal,setShowModal]=useState(false)
    const [step,setStep]=useState(1)

    useEffect(()=>{
    },[showModal])
    return (
        <div className="InteractivePatientHistory">
            <div className="InteractivePatientHistory__header">
            <div className="row">
        <div className="col-12">
          <div
            className="sec-title-ip text-center wow fadeInUp"
            data-wow-duration="1500ms"
          >
            <h6 className="sec-title-ip__tagline">
              <img
                src={secTitles1 }
                alt=""
                className="sec-title-ip__img"
              />
              Health NeuronAI
            </h6>
            {/* /.sec-title-ip__tagline */}
            <h3 className="sec-title-ip__title">
              AI Based Case History<br /> 
            </h3>
            {/* /.sec-title-ip__title */}
          </div>
          {/* /.sec-title-ip */}
        </div>
            </div></div>
            <div className="InteractivePatientHistory__body">
                <div className="InteractivePatientHistory__body__banner">
                    {showModal ==true?
                      step==1?  <RecordAudio setShowModal={setShowModal}/>:
                      step==2?<UploadAudio setShowModal={setShowModal} />:<></>:
                      <></>
                    
                    }
                </div>
            <div className="container-custom">
        <div className="container-custom__buttons">
      <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(1);setShowModal(true)}}
                className="laboix-btn-custom"
              >
                Record
              </a>
              <a
                // href="{{ url_for('image_processing') }}"
                onClick={()=>{setStep(2);setShowModal(true)}}
                className="laboix-btn-custom"
              >
               Upload
              </a>
              
      </div></div>
      {/* <div className="upload-predict" ref={uploadRef}>jhhhhhhhhhhhhhhhhhhhhhhhhh</div> */}
     {/* <Upload /> */}
     <div className="about-two__shape">
      <img
        src={aboutShape21}
        alt=""
      />
    </div>
            </div>
        </div>
    )
}

export default InteractivePatientHistory;