import React from "react";
import AnimatedCursor from "react-animated-cursor"
function CustomCursor(){
    return (
        <AnimatedCursor
  innerSize={8}
  outerSize={35}
  innerScale={1}
  outerScale={2}
  outerAlpha={0}
  hasBlendMode={true}
  innerStyle={{
    backgroundColor: "gray"
  }}
  outerStyle={{
    border: '3px solid gray'
  }}
  showSystemCursor={true}
/>
    )
}
export default CustomCursor;