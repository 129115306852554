// import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import "./vendors/bootstrap/css/bootstrap.min.css"
import "./vendors/bootstrap-select/bootstrap-select.min.css"
import "./vendors/animate/animate.min.css"
import "./vendors/jarallax/jarallax.css"
import "./vendors/jquery-ui/jquery-ui.css"
// import "./vendors/fontawesome/css/all.min.css"
import "./styles/laboix.css"
import "./vendors/owl-carousel/css/owl.theme.default.min.css";
import "./vendors/owl-carousel/css/owl.carousel.min.css";
import "./vendors/laboix-icons/style.css";
import "./vendors/nouislider/nouislider.pips.css";
import "./vendors/nouislider/nouislider.min.css";
import "./vendors/jquery-magnific-popup/jquery.magnific-popup.css"
import { Suspense } from 'react';
import Loader from './components/Loader';
import CustomCursor from './components/CustomCursor';
import { BrowserRouter as Router ,Route,Routes} from 'react-router-dom';
import Home from './screens/Home/Home';
import ImageProcessing from './screens/ImageProcessing';
import DataAnalytics from './screens/DataAnalytics';
import InteractivePatientHistory from './screens/InteractivePateientHistory';
import fav from "../src/media/favicons/fav.png"
function App() {
  
  return (
    <Suspense fallback={<Loader />}>
      
      <CustomCursor />
  <div className="App">
    <Router >
      <Routes>

    <Route path="/" element={<Home/>}/>
    <Route path="/imageProcessing" element={<ImageProcessing/>}/>
    <Route path="/dataAnalytics" element={<DataAnalytics/>}/>
    <Route path="/interactivePatientHistory" element={<InteractivePatientHistory/>}/>
    </Routes>
    </Router>
    </div>
    
    </Suspense>)
  
}

export default App;
