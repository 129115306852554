import axios from "axios"
import { RxCross1 } from "react-icons/rx"
import Upload from "../../components/Upload"
import './index.scss'
import { useEffect, useState } from "react"
import { dataset_info } from "./api/dataset_info"
import { train_status } from "./api/train_status"
import { train_model } from "./api/train_model"
import { evaluate_model } from "./api/evaluate_model"
import { predict } from "./api/predict"
class Steps{
    patientInfo=({setShowModal})=>{
        const [data,setData]=useState({})
        useEffect(()=>{
        const promise=dataset_info();
        promise.then((res)=>{console.log(res);
        setData({...res.data});
        
        }).catch((err)=>{
            console.log(err);
            
        }) },[])
        return (
<div className="modal-div">
                        <div className="modal-content-patient-info">
                        <div className="modal-header">
                           <span className="nodal-heading">Dataset Info</span> <RxCross1 size={"25PX"} onClick={()=>{setShowModal(false)}}/>
                            
                        </div>
                       {
                        Object.keys(data).map((val,ind)=>{
                            return (<div key={ind} className={val}>{`${val.toUpperCase()} - ${data[val]}`}</div>)
                        })
                       }
                        </div>
                    </div>
        )
    }
    train=({setShowModal})=>{
        const [data,setData]=useState({})
        const [status,setStatus]=useState("Training in Progress")
        const trainModel=()=>{
            const train_model_response=train_model()
            train_model_response.then((res)=>{
                setStatus(res.data.result)
                if(res.data.result!="completed")
                setInterval(trainStatus, 5000);
            })
        }
        const trainStatus=()=>{
            if(status!="completed")
           { 
            const train_status_res=train_status();
            train_status_res.then(res=>{
               setStatus(res.data.result)
            })}
        }
        useEffect(()=>{trainModel()},[])

            return (
                <div className="modal-div">
                        <div className="modal-content-patient-info">
                        <div className="modal-header">
                           <span>Model Train</span> <RxCross1 size={"25PX"} onClick={()=>{setShowModal(false)}}/>
                            
                        </div>
                       
                       <div className={"completed"} style={{color:`${status=="completed"?"green":"orange"}`}}>{status.toUpperCase()}</div>
                        </div>
                    </div>

            )
    }
    evaluate=({setShowModal})=>{
        const [data,setData]=useState({})
        useEffect(()=>{
        const promise=evaluate_model()
        promise.then((res)=>{console.log(res);
        setData({...res.data});
        
        }).catch((err)=>{
            console.log(err);
            
        }) },[])
        return (
            <div className="modal-div">
                        <div className="modal-content-patient-info">
                        <div className="modal-header">
                           <span className="modal-heading">Model Evaluation Result</span> <RxCross1 size={"25PX"} onClick={()=>{setShowModal(false)}}/>
                            
                        </div>
                       {
                        Object.keys(data).map((val,ind)=>{
                            return (<div key={ind} className={val}>{`${val} - ${data[val]}`}</div>)
                        })
                       }
                        </div>
                    </div>

        )
    }
    predict=({setShowModal})=>{
        const [image,setImage]=useState()
        const [result,setResult]=useState("")
        const predictModel=()=>{
                const formData=new FormData()
                formData.append('file',image)
                const response=predict(formData)
                response.then((res)=>{
                    console.log(res);
                  if(res.data.status=="true"){
                    setResult(res.data.result)
                  }
                })
              
        }
        return (<div className="modal-div">
                        <div className="modal-content">
                        <div className="modal-header">
                           <span> Diagnose online by uploading</span> <RxCross1 size={"25PX"} onClick={()=>{console.log("hmm");;setShowModal(false)}}/>
                            
                        </div>
                        <marquee style={{color:"orange"}}>Choose an image to be diagnosed and click on upload to fetch results</marquee>
                        <Upload uploadType={"image/*,"} setFile={setImage} onUpload={predictModel}/>
                        {/* <button onClick={predictModel}>Predict</button> */}
                        <div className={`result${result}`}>{result.toUpperCase()}</div>
                        </div>
                    </div>)
    }

}
export default new Steps();