import axios from "axios";

export const predict=async(formData)=>{
    return await axios.post(`https://health.neuronai.life/predict`,
        formData,
        {
          headers : {
              // accept: 'application/json',
              'content-type': 'multipart/form-data',
          }}
        
      );
}